<template>
  <div class="step1">
    <!-- <PlateTitle title="基本户信息" />
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-item
        label="企业基本户名称"
        has-feedback
      >
        <span>{{ dataSource.basicAccountName }}</span>
      </a-form-item>
      <a-form-item
        label="企业基本户账号"
        has-feedback
      >
        <span>{{ dataSource.basicAccountNo }}</span>
        <a
          v-if="step === 0"
          @click="goDoor"
        >&nbsp;&nbsp;&nbsp;&nbsp;修改信息</a>
      </a-form-item>
    </a-form> -->
    <PlateTitle title="投保信息" />
    <a-spin :spinning="spinning">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 10 }">
        <!-- <span v-if="step === 0">
          <a-form-item
            label="开立人"
            has-feedback
          >
            <span>{{ dataSource.openPeople }}</span>
          </a-form-item>
        </span> -->
        <a-form-item label="标段名称" has-feedback>
          <span>{{ dataSource.sectionName }}</span>
        </a-form-item>
        <a-form-item label="项目标段编码" has-feedback>
          <span>{{ dataSource.sectionCode }}</span>
        </a-form-item>
        <a-form-item label="受益人名称" has-feedback>
          <span>{{ dataSource.tendereeName }}</span>
        </a-form-item>
        <a-form-item label="受益人统一社会信用代码" has-feedback>
          <span>{{ dataSource.tendereeCreditCode }}</span>
        </a-form-item>
        <a-form-item label="投保企业名称" has-feedback>
          <span>{{ dataSource.name }}</span>
        </a-form-item>
        <a-form-item label="投保企业统一社会信用代码" has-feedback>
          <span>{{ dataSource.creditCode }}</span>
        </a-form-item>
        <!-- <a-form-item
        label="投保人手机号"
        has-feedback
      >
        <span>{{ dataSource.mobile }}</span>
      </a-form-item> -->
      </a-form>
      <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 8 }">
        <!-- <a-form-item
          label="经办人姓名"
          has-feedback
        >
          <a-input
            v-decorator="['name', {rules: [{validator: compareToFirstName},],}]"
            placeholder="请输入经办人姓名"
            style="width: 280px"
            :disabled="step === 1"
          />
        </a-form-item> -->
        <!-- <a-form-item
          label="经办人手机号"
          has-feedback
        >
          <a-input
            v-decorator="['phoneNum', {rules: [{validator: compareToFirstPhone},],}]"
            placeholder="请输入经办人手机号"
            style="width: 280px"
            v-if="step === 0"
          />
          <span v-if="step === 1">{{ dataSource.mobile }}</span>
        </a-form-item> -->

        <!-- <a-form-item
          label="投保企业联系人"
          has-feedback
          v-if="step === 0"
        >
          <a-input
            v-decorator="['principalName', {rules: [{required:true,message:'请输入投保企业联系人'},],}]"
            placeholder="请输入投保企业联系人"
            style="width: 280px"
          />
          <span v-if="step === 1">{{ dataSource.principalName }}</span>
        </a-form-item>
        <a-form-item
          label="投保企业联系人身份证件类型"
          has-feedback
          v-if="step === 0"
        >
          <a-select
            v-decorator="['principalCertType', {rules: [{required:true,message:'请选择投保企业联系人身份证件类型'},],}]"
            placeholder="请选择投保企业联系人身份证件类型"
            style="width: 280px"
          >
            <a-select-option
              v-for="(item,index) in certTypeList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <span v-if="step === 1">{{ dataSource.principalCertType }}</span>
        </a-form-item>
        <a-form-item
          label="投保企业联系人证件号码"
          has-feedback
          v-if="step === 0"
        >
          <a-input
            v-decorator="['principalIdCard', {rules: [{required:true,message:'请输入投保企业联系人证件号码'},],}]"
            placeholder="请输入投保企业联系人证件号码"
            style="width: 280px"
          />
          <span v-if="step === 1">{{ dataSource.principalIdCard }}</span>
        </a-form-item>
        <a-form-item
          label="投保企业联系人联系方式"
          has-feedback
          v-if="step === 0"
        >
          <a-input
            v-decorator="['principalPhone', {rules: [{required:true,validator: compareToFirstPhone},],}]"
            placeholder="请输入投保企业联系人联系方式"
            style="width: 280px"
          />
          <span v-if="step === 1">{{ dataSource.principalPhone }}</span>
        </a-form-item> -->
        <!-- <a-form-item
          label="法人手机"
          has-feedback
          v-if="step === 0"
        >
          <a-input
            v-decorator="['mobile', {rules: [{required:true,message:'请输入法人手机'},],}]"
            placeholder="请输入法人手机"
            style="width: 280px"
          />
        </a-form-item> -->
        <a-form-item label="经办人" has-feedback v-if="step === 0">
          <a-input
            v-decorator="[
              'principalName',
              { rules: [{ required: true, message: '请输入经办人' }] }
            ]"
            placeholder="请输入经办人"
            style="width: 280px"
          />
        </a-form-item>

        <a-form-item label="身份证号 " has-feedback v-if="step === 0">
          <a-input
            v-decorator="[
              'principalIdCard',
              { rules: [{ required: true, message: '请输入身份证号' }] }
            ]"
            placeholder="请输入身份证号"
            style="width: 280px"
          />
        </a-form-item>
        <a-form-item label="手机号码" has-feedback v-if="step === 0">
          <a-input
            v-decorator="[
              'principalPhone',
              { rules: [{ required: true, validator: compareToFirstPhone }] }
            ]"
            placeholder="手机号码"
            style="width: 280px"
          />
        </a-form-item>
        <!-- <a-form-item
          label="验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['code', {rules: [{validator: compareToFirstCode},],}]"
            placeholder="请输入验证码"
            style="width: 280px"
          />
          <img
            :src="codeImg"
            alt=""
            @click="code"
          >
        </a-form-item> -->
        <!-- <a-form-item
          label="短信验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['smsCode', {rules: [{validator: compareToFirstPhcode}],}]"
            placeholder="请输入短信验证码"
            style="width: 280px"
          />
          <a
            v-if="isTime"
            @click="phoneCode"
          >获取验证码</a>
          <span v-else>{{ time }}s重新获取</span>
        </a-form-item> -->
        <!-- <a-form-item
          label="营业执照"
          class="formItem"
          v-if="step === 0"
        >
          <div class="requiredUpload">
            *
          </div>
          <a-form-item class="items">
            <a-upload
              v-decorator="['businessLicenseImage',{rules: [{validator: compareFile1},],}]"
              name="file"
              :multiple="false"
              :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
              :headers="headers"
              :file-list="businessLicenseImage"
              accept=".jpg,.png"
              @change="(info)=>handleChange(info,'1')"
              @preview="handlePreview"
            >
              <a-button style="margin-right:50px;">
                {{ businessLicenseImage.length > 0?'重新上传':'点击上传' }}
              </a-button>
              <a-button
                v-if="businessLicenseImage.length > 0"
                @click="download('1')"
              >
                点击预览
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form-item> -->
        <!-- <a-form-item
          label="法人身份证正面"
          class="formItem"
          v-if="step === 0"
        >
          <div class="requiredUpload">
            *
          </div>
          <a-form-item class="items">
            <a-upload
              v-decorator="['legalPersonCardImage',{rules: [{validator: compareFile2},],}]"
              name="file"
              :multiple="false"
              :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
              :headers="headers"
              :file-list="legalPersonCardImage"
              accept=".jpg,.png"
              @change="(info)=>handleChange(info,'2')"
              @preview="handlePreview"
            >
              <a-button style="margin-right:50px;">
                {{ legalPersonCardImage.length > 0?'重新上传':'点击上传' }}
              </a-button>
              <a-button
                v-if="legalPersonCardImage.length > 0"
                @click="download('2')"
              >
                点击预览
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form-item> -->
        <!-- <a-form-item
          label="法人身份证反面"
          class="formItem"
          v-if="step === 0"
        >
          <div class="requiredUpload">
            *
          </div>
          <a-form-item class="items">
            <a-upload
              v-decorator="['legalPersonCardImage2',{rules: [{validator: compareFile2},],}]"
              name="file"
              :multiple="false"
              :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
              :headers="headers"
              :file-list="legalPersonCardImage2"
              accept=".jpg,.png"
              @change="(info)=>handleChange(info,'6')"
              @preview="handlePreview"
            >
              <a-button style="margin-right:50px;">
                {{ legalPersonCardImage2.length > 0?'重新上传':'点击上传' }}
              </a-button>
              <a-button
                v-if="legalPersonCardImage2.length > 0"
                @click="download('6')"
              >
                点击预览
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form-item> -->
        <!-- <a-form-item
          label="企业类型"
          has-feedback
          v-if="step === 0"
        >
          <a-select
            v-decorator="['companyType', {rules: [{required:true,message:'请输入企业类型'},],}]"
            placeholder="请输入企业类型"
            style="width: 280px"
          >
            <a-select-option
              v-for="(item,index) in certTypeList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <span v-if="step === 1">
          <a-form-item label="担保金额" has-feedback>
            <span>{{ throundsHandle(dataSource.sumAmount) }}元</span>
          </a-form-item>
          <!-- <a-form-item
            label="担保费"
            has-feedback
          >
            <span>{{ dataSource.sumPremium }}元</span>
          </a-form-item>
          <a-form-item
            label="费率"
            has-feedback
          >
            <span>{{ dataSource.rate }}%</span>
          </a-form-item> -->
        </span>
        <!-- <a-form-item
          label="验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['code', {rules: [{validator: compareToFirstCode},],}]"
            placeholder="请输入验证码"
            style="width: 280px"
          />
          <img
            :src="codeImg"
            alt=""
            @click="code"
          >
        </a-form-item>
        <a-form-item
          label="短信验证码"
          class="code"
          v-if="step === 0"
        >
          <a-input
            v-decorator="['phCode', {rules: [{validator: compareToFirstPhcode}],}]"
            placeholder="请输入短信验证码"
            style="width: 280px"
          />
          <a
            v-if="isTime"
            @click="phoneCode"
          >获取验证码</a>
          <span v-else>{{ time }}s重新获取</span>
        </a-form-item> -->
        <div v-if="step === 1 && isOpen">
          <div style="position:relative;">
            <PlateTitle title="企业资料" />
            <span
              style="position: absolute;left: 260px;top: 2px;font-size:14px;"
              >(支持jpg、png格式文件，文件不能大于5M)</span
            >
          </div>
          <span>
            <a-form-item label="营业执照" class="formItem">
              <div class="requiredUpload">
                *
              </div>
              <a-form-item class="items">
                <a-upload
                  v-decorator="[
                    'businessLicenseImage',
                    { rules: [{ validator: compareFile1 }] }
                  ]"
                  name="file"
                  :multiple="false"
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :headers="headers"
                  :file-list="businessLicenseImage"
                  accept=".jpg,.png"
                  @change="info => handleChange(info, '1')"
                  @preview="handlePreview"
                >
                  <a-button style="margin-right:50px;">
                    {{
                      businessLicenseImage.length > 0 ? "重新上传" : "点击上传"
                    }}
                  </a-button>
                  <a-button
                    v-if="businessLicenseImage.length > 0"
                    @click="download('1')"
                  >
                    点击预览
                  </a-button>
                </a-upload>
              </a-form-item>
            </a-form-item>
            <a-form-item label="法人身份证头像面" class="formItem">
              <div class="requiredUpload">
                *
              </div>
              <a-form-item class="items">
                <a-upload
                  v-decorator="[
                    'legalPersonCardImage',
                    { rules: [{ validator: compareFile2 }] }
                  ]"
                  name="file"
                  :multiple="false"
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :headers="headers"
                  :file-list="legalPersonCardImage"
                  accept=".jpg,.png"
                  @change="info => handleChange(info, '2')"
                  @preview="handlePreview"
                >
                  <a-button style="margin-right:50px;">
                    {{
                      legalPersonCardImage.length > 0 ? "重新上传" : "点击上传"
                    }}
                  </a-button>
                  <a-button
                    v-if="legalPersonCardImage.length > 0"
                    @click="download('3')"
                  >
                    点击预览
                  </a-button>
                </a-upload>
              </a-form-item>
            </a-form-item>
          </span>
          <!-- signedFlag   0：未签署 1：已签署 -->
          <div style="position:relative;">
            <PlateTitle title="开函资料" />
            <span
              style="position: absolute;left: 260px;top: 2px;font-size:14px;"
              >(支持pdf,doc,docx,png,jpg格式文件，文件不能大于5M)</span
            >
          </div>
          <span>
            <a-form-item
              label="委托担保协议"
              class="formItem"
              v-if="this.form1.signedFlag === '1'"
            >
              <div class="requiredUpload">*</div>
              <a-form-item class="items">
                <a-button
                  style="margin-right:50px;"
                  @click="toQian"
                  v-if="this.form1.signedFlag === '0'"
                  >去签署</a-button
                >
                <a-upload
                  v-decorator="[
                    'bidCommitmentUrl',
                    { rules: [{ validator: compareFile4 }] }
                  ]"
                  name="file"
                  :multiple="false"
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :headers="headers"
                  :file-list="bidCommitmentUrl"
                  accept=".pdf,.doc,.docx,.jpg,.png"
                  @change="info => handleChange(info, '4')"
                  @preview="handlePreview"
                  v-if="this.form1.signedFlag != '0'"
                >
                  <!-- <a-button style="margin-right:50px;">{{ bidCommitmentUrl.length > 0?'重新上传':'点击上传' }}</a-button> -->
                  <a-button
                    v-if="bidCommitmentUrl.length > 0"
                    @click="download('4')"
                    >点击预览</a-button
                  >
                  <!-- <a-button
                  style="margin-left: 55px;color: #fff;background-color: #1890ff;border:none"
                  @click="downloadFile('1')"
                >下载模板</a-button><br>
                <span style="display:block;margin-top:10px">(下载模板文件打印，法人签字+盖公章上传PDF扫描件)</span> -->
                </a-upload>
              </a-form-item>
            </a-form-item>
            <a-form-item
              label="出具分离式保函承诺书"
              v-if="this.form1.signedFlag === '1'"
              class="formItem"
            >
              <div class="requiredUpload">*</div>
              <a-form-item class="items">
                <a-upload
                  v-decorator="[
                    'separateGuaranteePromiseUrl',
                    { rules: [{ validator: compareFile5 }] }
                  ]"
                  name="file"
                  :multiple="false"
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :headers="headers"
                  :file-list="separateGuaranteePromiseUrl"
                  accept=".pdf,.doc,.docx,.jpg,.png"
                  @change="info => handleChange(info, '5')"
                  @preview="handlePreview"
                  v-if="this.form1.signedFlag != '0'"
                >
                  <!-- <a-button style="margin-right:50px;">{{ separateGuaranteePromiseUrl.length > 0?'重新上传':'点击上传' }}</a-button> -->
                  <a-button
                    v-if="separateGuaranteePromiseUrl.length > 0"
                    @click="download('5')"
                    >点击预览</a-button
                  >
                  <!-- <a-button
                  style="margin-left: 55px;color: #fff;background-color: #1890ff;border:none"
                  @click="downloadFile('2')"
                >下载模板</a-button>
                <span style="display:block;margin-top:10px">(下载模板文件打印，法人签字+盖公章上传PDF扫描件)</span> -->
                </a-upload>
              </a-form-item>
            </a-form-item>
            <a-form-item label="招标文件">
              <a-form-item class="items">
                <a-upload
                  v-decorator="[
                    'biddingDocumentUrl',
                    { rules: [{ required: false }] }
                  ]"
                  name="file"
                  :multiple="false"
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :headers="headers"
                  :file-list="biddingDocumentUrl"
                  accept=".pdf,.doc,.docx,.jpg,.png"
                  @change="info => handleChange(info, '3')"
                  @preview="handlePreview"
                >
                  <a-button style="margin-right:50px;">{{
                    biddingDocumentUrl.length > 0 ? "重新上传" : "点击上传"
                  }}</a-button>
                  <a-button
                    v-if="biddingDocumentUrl.length > 0"
                    @click="download('3')"
                    >点击预览</a-button
                  >
                </a-upload>
              </a-form-item>
            </a-form-item>
          </span>
        </div>
        <!-- <PlateTitle
          title="保函机构"
          v-if="step === 1"
        /> -->
        <!-- <div
          class="content"
          v-if="step === 1"
        >
          <div v-if="planList1.length != 0">
            <div class="tit">
              担保公司
            </div>
            <div class="palnList">
              <div
                v-for="item in planList1"
                :key="item.id"
                @click="show(item)"
                class="borders"
                :class="{ palnListBox: palnListBox,palnListBoxAct: item.isPalnList}"
              >
                <div
                  class="feilv"
                >
                  费率千三
                </div>
                <img :src="item.logoPath">
                <div class="palnText">
                  <span>{{ item.planName }}</span>
                  <p>{{ item.support }}</p>
                </div>
                <div>
                  {{ item.partnerName }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="planList2.length != 0">
            <div class="tit">
              保险公司
            </div>
            <div class="palnList">
              <div
                v-for="item in planList2"
                :key="item.id"
                @click="show(item)"
                class="borders"
                :class="{ palnListBox: palnListBox,palnListBoxAct: item.isPalnList}"
              >
                <div
                  class="feilv"
                >
                  费率千三
                </div>

                <img :src="item.logoPath">
                <img
                  src="https://www.cnca.net/dzbh/tbbh-pc/wzt-platform/static/image/hanhua.png"
                >

                <div class="palnText">
                  <span>{{ item.planName }}</span>
                  <p>{{ item.support }}</p>
                </div>
                <div>
                  {{ item.partnerName }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="planList3.length != 0">
            <div class="tit">
              银行
            </div>
            <div class="palnList">
              <div
                v-for="item in planList3"
                :key="item.id"
                @click="show(item)"
                class="borders"
                :class="{ palnListBox: palnListBox,palnListBoxAct: item.isPalnList}"
              >
                <div
                  class="feilv"
                >
                  费率千三
                </div>
                <img :src="item.logoPath">
                <div class="palnText">
                  <span>{{ item.planName }}</span>
                  <p>{{ item.support }}</p>
                </div>
                <div>
                  {{ item.partnerName }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 机构弹窗 -->
        <div class="plastDialog">
          <a-modal
            v-model="visible"
            title="机构"
            cancel-text="取消"
            ok-text="确认"
            @ok="handleOk"
          >
            <a-form-model
              ref="ruleForm"
              :model="form1"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item ref="name" label="产品名称" prop="name">
                <div>{{ this.form1.productName }}</div>
              </a-form-model-item>
              <a-form-item label="担保费" has-feedback>
                <span>{{ this.form1.sumPremium }}元</span>
              </a-form-item>
              <a-form-item label="费率" has-feedback>
                <span>{{ this.form1.rate }}%</span>
              </a-form-item>
              <a-form-model-item
                ref="name"
                label="收费规则"
                prop="name"
                v-if="this.form1.chargeRuleStr"
              >
                <div style="width: 290px;">
                  {{ this.form1.chargeRuleStr }}
                </div>
              </a-form-model-item>
              <a-form-model-item
                ref="name"
                label="基本要求"
                prop="name"
                v-if="this.form1.applyDetail"
              >
                <div>{{ this.form1.applyDetail }}</div>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
        </div>
        <!-- 去签署 -->
        <div class="plastDialog">
          <a-modal
            v-model="visible2"
            :closable="true"
            :mask-closable="false"
            title="签署"
            class="plastDialog2"
          >
            <a-form
              :form="form"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 4 }"
            >
              <div style="text-indent:2em;line-height:40px;">
                应银行要求，投保人投保前需要签署《委托担保协议》，《出具分离式保函承诺书》。
                <a-button
                  style="margin-right:50px;"
                  @click="toQian"
                  v-if="this.form1.signedFlag === '0'"
                  type="primary"
                >
                  去签署
                </a-button>
              </div>
            </a-form>
          </a-modal>
        </div>
        <a-form-item v-if="step === 1" label="出函银行" has-feedback>
          <a-radio-group
            name="radioGroup"
            :default-value="de_value"
            @change="onChange1"
          >
            <a-radio v-for="item in typeArr" :key='item.id' :value="item.partnerCode">{{item.partnerName}}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="step === 0">
          <a-checkbox
            v-decorator="[
              'checked',
              { rules: [{ validator: compareToFirstChecked }] }
            ]"
            style="width: 500px;"
          >
            已阅读并同意
            <!-- <a
              v-for="(item,index) in readDocMap"
              :key="index"
              class="hrefa"
              :href="item"
            >{{ index }}</a> -->
            <a :href="downloadBook('rules')" target="_blank"
              >《投标保函开立、退费、代偿规则》</a
            >
            <a :href="downloadBook('manual')" target="_blank"
              >《电子保函服务平台操作手册》</a
            >
            <a :href="downloadBook('apply')" target="_blank"
              >《投标保函申请书》</a
            >
          </a-checkbox>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import PlateTitle from "@/components/PlateTitle";
import {
  code,
  phoneCode,
  getList,
  getChannelInfo,
  certType,
  startSign
} from "@/api/insure.js";
import { throttle } from "@/utils/format.js";
// const querystring = require('querystring');
export default {
  name: "Step1",
  components: {
    PlateTitle
  },
  props: {
    step: {
      type: Number,
      default: 0
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      }
    },
    spinning: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      invoiceType: "2",
      VUE_APP_FIRST_URL: "",
      headers: {
        // authorization: 'authorization-text',
        Authorization: localStorage.getItem("token")
      },
      uuid: "",
      codeImg: "",
      isTime: true,
      time: 60,
      form: this.$form.createForm(this, { name: "loginForm" }),
      orderNo: "",
      planList1: [],
      planList2: [],
      planList3: [],
      response: [],
      visible: false,
      palnListBox: true,
      isPalnList: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form1: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        partnerCode: "dongGuanBank"
      },
      de_value:'',
      rules: {},
      businessLicenseImage: [], // 营业执照
      businessUrl: "", // 营业执照预览地址
      legalPersonCardImage: [], // 法人身份证头像面
      legalUrl: "", // 法人身份证头像面预览地址
      legalPersonCardImage2: [], // 法人身份证头像面
      legalUrl2: "", // 法人身份证头像面预览地址
      biddingDocumentUrl: [], // 招标文件
      biddingUrl: "", // 招标文件预览地址
      bidCommitmentUrl: [], // 委托担保协议
      bidCommitUrl: "", // 委托担保协议预览地址
      separateGuaranteePromiseUrl: [], // 出具分离式保函承诺书
      separateUrl: "", // 出具分离式保函承诺书预览地址
      file1: false,
      file2: false,
      file3: false,
      file4: false,
      file5: false,
      readDocMap: "", // 已阅读并同意文件
      certTypeList: [], // 身份证件类型
      isOpen: false,
      visible2: false,
      clicktag: 0,
      typeArr:[]
    };
  },
  mounted() {
    this.init();
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
    // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
    this.orderNo = this.getQueryString("orderNo");
    this.code();
    this.fetch();
  },
  methods: {
    throundsHandle(value) {
      if (value) {
        let a = value.toString().split(".")[0];
        let b = value.toString().split(".")[1];
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + (b ? "." + b : "");
      } else {
        return value;
      }
    },
    onChange1(e) {
      this.form1.partnerCode = e.target.value;
    },
    downloadBook(type) {
      // download({ type, orderNo: this.orderNo }).then(res => {
      //   console.log(res);
      // });
      return `${process.env.VUE_APP_FIRST_URL}/api/insure/index/download?type=${type}&orderNo=${this.orderNo}`;
    },
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    /* 投保完成显示开票信息 */
    init() {
      this.form.setFieldsValue({
        mobile: this.dataSource.mobile,
        principalName: this.dataSource.principalName,
        principalCertType: this.dataSource.principalCertType,
        principalIdCard: this.dataSource.principalIdCard,
        principalPhone: this.dataSource.principalPhone,
        companyType: this.dataSource.companyType,
        principalLegalPerson: this.dataSource.principalLegalPerson,
        principalLegalCertType: this.dataSource.principalLegalCertType,
        principalLegalIdCard: this.dataSource.principalLegalIdCard,
        principalLegalPhone: this.dataSource.principalLegalPhone,
        marketers: this.dataSource.marketers
      });
      this.de_value=this.dataSource.partnerSource
      this.form1.partnerCode = this.de_value ? this.de_value :''
      // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
      this.orderNo = this.getQueryString("orderNo");
      getChannelInfo({ orderNo: this.orderNo }).then(res => {
        this.readDocMap = res.data.readDocMap;
      });
      certType().then(res => {
        this.certTypeList = res.data.data;
      });
      if (this.dataSource.legalPersonCardImage) {
        const legalPersonCardImage = this.dataSource.legalPersonCardImage.split(
          ","
        );
        this.dataSource.legalPersonCardImage = legalPersonCardImage[0];
        this.dataSource.legalPersonCardImage2 = legalPersonCardImage[1];
      }
      this.businessLicenseImage = this.dataSource.businessLicenseImage
        ? [
            {
              uid: "1",
              name: "营业执照",
              status: "done",
              url: this.dataSource.businessLicenseImage
            }
          ]
        : [];
      if (this.dataSource.businessLicenseImage) {
        this.file1 = true;
      }
      this.legalPersonCardImage = this.dataSource.legalPersonCardImage
        ? [
            {
              uid: "1",
              name: "法人身份证正面",
              status: "done",
              url: this.dataSource.legalPersonCardImage
            }
          ]
        : [];
      if (this.dataSource.legalPersonCardImage) {
        this.file2 = true;
      }
      this.legalPersonCardImage2 = this.dataSource.legalPersonCardImage2
        ? [
            {
              uid: "1",
              name: "法人身份证反面",
              status: "done",
              url: this.dataSource.legalPersonCardImage2
            }
          ]
        : [];
      if (this.dataSource.legalPersonCardImage2) {
        this.file2 = true;
      }
      this.biddingDocumentUrl = this.dataSource.biddingDocumentUrl
        ? [
            {
              uid: "1",
              name: "招标文件",
              status: "done",
              url: this.dataSource.biddingDocumentUrl
            }
          ]
        : [];
      if (this.dataSource.biddingDocumentUrl) {
        this.file3 = true;
      }
      this.bidCommitmentUrl = this.dataSource.bidCommitmentUrl
        ? [
            {
              uid: "1",
              name: "委托担保协议",
              status: "done",
              url: this.dataSource.bidCommitmentUrl
            }
          ]
        : [];
      if (this.dataSource.bidCommitmentUrl) {
        this.file4 = true;
      }
      this.separateGuaranteePromiseUrl = this.dataSource
        .separateGuaranteePromiseUrl
        ? [
            {
              uid: "1",
              name: "出具分离式保函承诺书",
              status: "done",
              url: this.dataSource.separateGuaranteePromiseUrl
            }
          ]
        : [];
      if (this.dataSource.separateGuaranteePromiseUrl) {
        this.file5 = true;
      }
      // this.legalPersonCardImage = this.dataSource.legalPersonCardImage ? this.dataSource.legalPersonCardImage : [];
      // this.biddingDocumentUrl = this.dataSource.biddingDocumentUrl ? this.dataSource.biddingDocumentUrl : [];
      // this.bidCommitmentUrl = this.dataSource.bidCommitmentUrl ? this.dataSource.bidCommitmentUrl : [];
      // this.separateGuaranteePromiseUrl = this.dataSource.separateGuaranteePromiseUrl ? this.dataSource.separateGuaranteePromiseUrl : [];
    },
    goDoor() {
      this.$router.push({
        name: "BasicDoor",
        query: { id: this.dataSource.id }
      });
    },
    /* 图片验证码 */
    code() {
      code().then(res => {
        this.codeImg = res.data.img;
        this.uuid = res.data.uuid;
      });
    },
    /* 获取验证码 */
    phoneCode() {
      this.timer = "";
      console.log(this.form.instances);
      if (this.form.instances.principalPhone.stateValue !== "") {
        phoneCode({
          phoneNum: this.form.instances.principalPhone.stateValue
        }).then(res => {
          if (res.data === "") {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning("手机号码不能为空");
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 60;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },

    // 自定义校验
    compareToFirstName(rule, value, callback) {
      if (value === "" || !value) {
        callback("请输入经办人姓名");
      } else {
        callback();
      }
    },
    compareToFirstPhone(rule, value, callback) {
      const pattern = /^1[0-9]{10}$/;
      if (pattern.test(value) && value) {
        callback();
      } else {
        callback("请输入正确的手机号");
      }
    },
    compareToFirstCode(rule, value, callback) {
      if (value === "" || !value) {
        callback("请输入图形验证码");
      } else {
        callback();
      }
    },
    compareToFirstPhcode(rule, value, callback) {
      if (value === "" || !value) {
        callback("请输入短信验证码");
      } else {
        callback();
      }
    },
    compareToFirstChecked(rule, value, callback) {
      if (!value) {
        callback("请勾选协议");
      } else {
        callback();
      }
    },
    compareFile1(rule, value, callback) {
      if (this.businessLicenseImage.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    compareFile2(rule, value, callback) {
      if (this.legalPersonCardImage.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    compareFile3(rule, value, callback) {
      if (this.legalPersonCardImage2.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    // compareFile3(rule, value, callback) {
    //   if (this.biddingDocumentUrl.length > 0) {
    //     callback();
    //   } else {
    //     callback('请上传文件');
    //   }
    // },
    compareFile4(rule, value, callback) {
      if (this.bidCommitmentUrl.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    compareFile5(rule, value, callback) {
      if (this.separateGuaranteePromiseUrl.length > 0) {
        callback();
      } else {
        callback("请上传文件");
      }
    },
    palnListChange(e) {
      this.isOpen = false;
      this.planValue = e.id;
      this.planList.map(item => {
        if (item.id === e.id) {
          item.isPalnList = true;
        } else {
          item.isPalnList = false;
        }
        return false;
      });
    },
    fetch() {
      this.planList1 = [];
      this.planList2 = [];
      this.planList3 = [];
      // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
      this.orderNo = this.getQueryString("orderNo");
      getList({ orderNo: this.orderNo }).then(res => {
        this.typeArr=res.data.data;
        console.log('---0',this.typeArr);
        this.response = res.data.data;
        this.response.forEach(item => {
          if (item.signedFlag === "1") {
            this.isOpen = true;
          }
        });
        // 1:担保，2：保险公司，3：银行
        this.response.forEach(item => {
          if (item.partnerType === "1") {
            this.planList1.push(item);
          }
        });
        this.response.forEach(item => {
          if (item.partnerType === "2") {
            this.planList2.push(item);
          }
        });
        this.response.forEach(item => {
          if (item.partnerType === "3") {
            this.planList3.push(item);
          }
        });
        this.planList1.forEach(item => {
          if (this.step === 1 && item.signedFlag === "1") {
            this.visible2 = false;
            item.isPalnList = true;
            this.form1 = item;
          }
        });
        this.planList2.forEach(item => {
          if (this.step === 1 && item.signedFlag === "1") {
            this.visible2 = false;
            item.isPalnList = true;
            this.form1 = item;
          }
        });
        this.planList3.forEach(item => {
          if (this.step === 1 && item.signedFlag === "1") {
            this.visible2 = false;
            item.isPalnList = true;
            this.form1 = item;
          }
        });
      });
    },
    show(e) {
      // this.isOpen = false;
      this.form1 = { ...e };
      if (
        this.step === 1 &&
        e.signedFlag === "0" &&
        e.partnerCode === "xiaMenBank"
      ) {
        this.visible2 = true;
      } else {
        this.visible2 = false;
      }
      if (this.step === 1 && e.signedFlag === "1") {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
      this.response.map(item => {
        if (e.id === item.id) {
          item.isPalnList = true;
        } else {
          item.isPalnList = false;
        }
        return false;
      });
    },
    // showModal() {
    //   if (!this.form1.id) {
    //     this.$notification.error({
    //       message: '请选择保函机构',
    //     });
    //     return;
    //   }
    //   this.visible = true;
    //   return this.form1;
    // },
    hiddenModal() {
      this.visible = false;
    },
    handleOk() {
      // this.$parent.confirm();
      this.$emit("confirm", this.form1);
    },
    beforeUpload1(file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        console.log(file);
      } else {
        this.$message.warning("仅支持jpg,png文件格式");
        return false;
      }
    },
    // 方法合并，代码优化
    handleChange(info, num) {
      if (num === "1") {
        this.handleChange1(info);
      } else if (num === "2") {
        this.handleChange2(info);
      } else if (num === "3") {
        this.handleChange3(info);
      } else if (num === "4") {
        this.handleChange4(info);
      } else if (num === "5") {
        this.handleChange5(info);
      } else if (num === "6") {
        this.handleChange6(info);
      }
    },
    handleChange1(info) {
      this.businessLicenseImage =
        info.file.status && info.file.status !== "error"
          ? info.fileList.slice(-1)
          : [];
      this.businessUrl = info.file.response ? info.file.response.data.url : "";
      this.file1 = false;
      // if (info.file.status !== 'uploading') {
      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.businessLicenseImage = [];
      } else if (info.file.status === "removed") {
        this.businessLicenseImage = [];
      }
    },
    handleChange2(info) {
      this.legalPersonCardImage = info.fileList.slice(-1);
      this.legalUrl = info.file.response ? info.file.response.data.url : "";
      this.file2 = false;
      // if (info.file.status !== 'uploading') {
      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.legalPersonCardImage = [];
      } else if (info.file.status === "removed") {
        this.legalPersonCardImage = [];
      }
    },
    handleChange3(info) {
      this.biddingDocumentUrl = info.fileList.slice(-1);
      this.biddingUrl = info.file.response ? info.file.response.data.url : "";
      this.file3 = false;
      // if (info.file.status !== 'uploading') {
      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.biddingDocumentUrl = [];
      } else if (info.file.status === "removed") {
        this.biddingDocumentUrl = [];
      }
    },
    handleChange4(info) {
      this.bidCommitmentUrl = info.fileList.slice(-1);
      this.bidCommitUrl = info.file.response ? info.file.response.data.url : "";
      this.file4 = false;
      // if (info.file.status !== 'uploading') {
      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.bidCommitmentUrl = [];
      } else if (info.file.status === "removed") {
        this.bidCommitmentUrl = [];
      }
    },
    handleChange5(info) {
      this.separateGuaranteePromiseUrl = info.fileList.slice(-1);
      this.separateUrl = info.file.response ? info.file.response.data.url : "";
      this.file5 = false;
      // if (info.file.status !== 'uploading') {

      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.separateGuaranteePromiseUrl = [];
      } else if (info.file.status === "removed") {
        this.separateGuaranteePromiseUrl = [];
      }
    },
    handleChange6(info) {
      this.legalPersonCardImage2 = info.fileList.slice(-1);
      this.legalUrl2 = info.file.response ? info.file.response.data.url : "";
      this.file2 = false;
      // if (info.file.status !== 'uploading') {
      // }
      if (info.file.status === "done") {
        this.$message.success("上传成功");
      } else if (info.file.status === "error") {
        this.$message.error("上传失败");
        this.legalPersonCardImage2 = [];
      } else if (info.file.status === "removed") {
        this.legalPersonCardImage2 = [];
      }
    },
    download(num) {
      if (num === "1") {
        const url = this.businessUrl
          ? this.businessUrl
          : this.dataSource.businessLicenseImage;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.businessUrl
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      } else if (num === "2") {
        const url = this.legalUrl
          ? this.legalUrl
          : this.dataSource.legalPersonCardImage;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.legalUrl
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      } else if (num === "3") {
        const url = this.biddingUrl
          ? this.biddingUrl
          : this.dataSource.biddingDocumentUrl;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.biddingUrl
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      } else if (num === "4") {
        const url = this.bidCommitUrl
          ? this.bidCommitUrl
          : this.dataSource.bidCommitmentUrl;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.bidCommitUrl
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      } else if (num === "5") {
        const url = this.separateUrl
          ? this.separateUrl
          : this.dataSource.separateGuaranteePromiseUrl;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.separateUrl
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      } else if (num === "6") {
        const url = this.legalUrl2
          ? this.legalUrl2
          : this.dataSource.legalPersonCardImage2;
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = this.legalUrl2
          ? this.VUE_APP_FIRST_URL + url
          : this.VUE_APP_FIRST_URL + "/api/contract/download?source=" + url;
        a.click();
      }
    },
    downloadFile(num) {
      if (num === "1") {
        const a = document.createElement("a");
        a.href =
          "/api/insure/index/download?type=XIA_MEN_BANK_BID_COMMITMENT&orderNo=HZT22060916547586593909151090710";
        a.click();
      } else if (num === "2") {
        const a = document.createElement("a");
        a.href =
          "/api/insure/index/download?type=XIA_MEN_BANK_SEPARATE_GUARANTEE_PROMISE&orderNo=HZT22060916547586593909151090710";
        a.click();
      }
    },
    toQian: throttle(function() {
      this.toQian2();
    }, 3000),
    toQian2() {
      startSign({
        orderNo: this.orderNo,
        partnerCode: this.form1.partnerCode
      }).then(res => {
        window.open(res.data.url);
      });
    },
    // 自定义文件预览事件
    handlePreview() {}
  }
};
</script>

<style lang="less" scoped>
.step1 {
  /deep/ .ant-row {
    display: flex;
    margin-left: 15%;
    // justify-content: center;
  }
  .hrefa {
    // color: #666
    color: #1890ff;
  }
  .code {
    position: relative;
    img {
      position: absolute;
      cursor: pointer;
      left: 185px;
      top: -5px;
      height: 30px;
    }
    a,
    span {
      position: absolute;
      width: 80px;
      left: 200px;
      transform: translateY(-5px);
    }
  }
}

.palnList {
  display: flex;
  flex-wrap: wrap;
  .palnListBox {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 425px;
    height: 80px;
    margin-bottom: 20px;
    position: relative;
    background: #ffffff;
    border: 2px solid #d2e1ec;
    border-radius: 4px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6a95b5;
    margin-right: 30px;
    cursor: pointer;
    // padding-left: 25px;
    transition: all 0.5s;
    .palnText {
      margin: 20px 0 0 17px;
      span {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
      }
    }
    img {
      // width: 330px;
      // height: 75px;
      height: 74px;
    }
    .feilv {
      position: absolute;
      text-align: center;
      top: -2px;
      right: -2px;
      width: 60px;
      height: 20px;
      background: #f83333;
      border-radius: 0px 4px 0px 10px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .palnListBoxAct {
    border: 2px solid #36a4ff;
    background: linear-gradient(-30deg, #e6f3fd 0%, #ffffff 100%);
    color: #007ce2;
    transition: all 0.5s;
  }
}
.content {
  margin-left: 15%;
}
.tit {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 30px 157px;
  span {
    font-size: 14px;
    font-weight: 300;
    padding-left: 10px;
    color: black;
  }
}
::v-deep .ant-form-item-control {
  margin-left: 50px;
  width: 370px;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.items {
  margin-left: 0 !important;
  ::v-deep .ant-form-item-control {
    margin-left: 0 !important;
  }
  ::v-deep .ant-col {
    height: 30px;
  }
  ::v-deep .ant-upload-list-item-card-actions .anticon {
    display: none;
  }
}
.items2 {
  margin-left: 0 !important;
  ::v-deep .ant-form-item-control {
    margin-left: 0 !important;
    width: 260px;
  }
  ::v-deep .ant-col {
    height: 30px;
  }
}
.plastDialog2 {
  ::v-deep .ant-modal-footer {
    display: none;
  }
}
.formItem {
  ::v-deep .ant-form-item-label {
    padding-left: 11px;
  }
}
.requiredUpload {
  position: absolute;
  left: -280px;
  top: 13px;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
.ant-form-item {
  margin-bottom: 13px;
}
</style>
