<template>
  <div class="container">
    <Progress :active-index="step" :step-list="stepList" />
    <div class="main">
      <!-- <a-spin :spinning="spinning" /> -->
      <Step2 v-if="step === 2" />
      <Step1
        ref="step1"
        v-if="step === 0 || step === 1"
        :step="step"
        :data-source="dataSource"
        :spinning="spinning"
        @confirm="confirm"
      />
      <Operate class="operate" :step="step" :is-next="isNext" />
    </div>
    <!-- <DocumentDialog
      class="insureInfo"
      title="保函开立须知"
      :content="invitationLetterStr"
      ok-text="我已阅读开立须知并同意"
      cancel-text="不同意，取消开立"
      :is-show-know-tips="false"
      :visible="insureInfoDialog.visible"
      @ok="()=>{insureInfoDialog.visible=false}"
      @cancel="()=>{insureInfoDialog.visible=false;closePage()}"
      v-if="step !== 2"
    /> -->
    <div class="notice-dialog">
      <a-modal
        v-if="step === 0"
        v-model="insureInfoDialog.visible"
        title="保函开立须知"
        centered
        cancel-text="不同意，取消开立"
        ok-text="我已阅读开立须知并同意"
        :mask-closable="false"
        :closable="false"
        @cancel="
          () => {
            insureInfoDialog.visible = false
            closePage()
          }
        "
        @ok="handleClickOks"
      >
        <div class="content" v-html="insureNotice" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import invitationLetterTpl from '@/assets/tpl/invitationLetter.js';
import Progress from './../../components/Progress';
import Operate from './components/Operate';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import { information, getInformation, verify, applyInsureMoneyVerify, getChannelInfo, certType, getList } from '@/api/insure.js';
// import handlebars from 'handlebars';
// import DocumentDialog from '@/components/DocumentDialog';
// import PickCompany from './components/PickCompany';
// const querystring = require('querystring');

export default {
  name: 'InsurePage',
  components: {
    Progress,
    Operate,
    // DocumentDialog,
    Step1,
    Step2,
  },
  data () {
    return {
      spinning: false,
      orderNo: '',
      status: '',
      step: 2,
      dataSource: {},
      invitationLetterTpl,
      stepList: ['填写投保信息', '确认投保信息', '提交投保完成'],
      pickCompanyCode: '',
      insureInfoDialog: {
        visible: true,
      },
      isNext: false,
      upLoadList: {},
      insureNotice: '',
      certTypeList: [],
    };
  },
  created () {
    if (window.localStorage.getItem('visible') == 'false') {
      this.insureInfoDialog.visible = false
    }
  },
  mounted () {
    this.getInformation();
    this.init();
  },
  methods: {
    handleClickOks () {
      this.insureInfoDialog.visible = false
      window.localStorage.setItem('visible', false)
    },
    getQueryString (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
    init () {
      // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
      this.orderNo = this.getQueryString('orderNo');
      getChannelInfo({ orderNo: this.orderNo }).then(res => {
        this.insureNotice = res.data.insureNotice;
      });
      certType().then(res => {
        this.certTypeList = res.data.data;
      });
    },
    getInformation () {
      // this.orderNo = querystring.parse(window.location.search)['?orderNo'];
      this.orderNo = this.getQueryString('orderNo');
      getInformation({ orderNo: this.orderNo }).then(response => {
        const res = response.data;
        this.status = res.status;
        this.dataSource = res;
        // 暂时隐藏;
        if (res.status === '-1' || !res.mobile) {
          this.step = 0;
          // if (res.mobile) {
          //   this.step = 1;
          // }
          this.orderNo = this.getQueryString('orderNo');
          getList({ orderNo: this.orderNo }).then(res => {
            res.data.data.forEach(item => {
              if (item.signedFlag === '1') {
                this.step = 1;
              }
            });
          });
        } else {
          this.step = 2;
        }
        // this.step = 2;
        applyInsureMoneyVerify({ orderId: this.orderNo }).then(res => {
          if (res.status === 500 && (this.step === 0 || this.step === 1)) {
            this.$message.error(res.message);
            this.isNext = true;
          }
        });
      });
    },
    // getCaption(obj, state) {
    //   const index = obj.lastIndexOf('?');
    //   if (state === 0) {
    //     obj = obj.substring(0, index);
    //   } else {
    //     obj = obj.substring(index + 1, obj.length);
    //   }
    //   return obj;
    // },
    // 下一步
    information () {
      if (this.step === 0) {
        this.$refs.step1.form.validateFields((err, values) => {
          if (!err) {
            // if (!values.checked) {
            //   this.$message.warning('请勾选协议');
            //   return false;
            // }
            this.spinning = true;
            console.log(values);
            console.log(this.$refs.step1.legalPersonCardImage2);
            // eslint-disable-next-line no-unused-vars
            let legalPersonCardImage = '';
            if (!this.$refs.step1.file2 && values.legalPersonCardImage) {
              legalPersonCardImage = values.legalPersonCardImage.file.response.data.uri + ',' + values.legalPersonCardImage2.file.response.data.uri;
            } else {
              legalPersonCardImage = this.$refs.step1.legalPersonCardImage[0].url + ',' + this.$refs.step1.legalPersonCardImage2[0].url;
            }
            const data = {
              orderNo: this.orderNo,
              ...values,
              businessLicenseImage: !this.$refs.step1.file1 && values.businessLicenseImage ? values.businessLicenseImage.file.response.data.uri : this.$refs.step1.businessLicenseImage[0].url,
              legalPersonCardImage,
              // responsiblePersonPhone: values.phoneNum,
              // principalName: values.principalName,
              // principalCertType: values.principalCertType,
              // principalIdCard: values.principalIdCard,
              // principalPhone: values.principalPhone,
              // smsCode: values.smsCode,
              // principalLegalPerson: values.principalLegalPerson,
              // principalLegalCertType: values.principalLegalCertType,
              // principalLegalIdCard: values.principalLegalIdCard,
              // principalLegalPhone: values.principalLegalPhone,
              // marketers: values.marketers,
            }

            information(data).then(res => {
              console.log(res);
              if (res.status === 200) {
                // 添加FAIL状态判断
                if(res.data.status==='FAIL'){
                  this.spinning = false;
                  this.$notification.error({
                  message: res.data.errorMsg,
                });
                  return
                 }

                this.step = 1;
                this.$refs.step1.fetch();
                this.dataSource.mobile = values.phoneNum;
                getInformation({ orderNo: this.orderNo }).then(res => {
                  this.dataSource = res.data;
                  this.certTypeList.forEach(item => {
                    if (this.dataSource.principalCertType === item.value) {
                      this.dataSource.principalCertType = item.label;
                    }
                    if (this.dataSource.principalLegalCertType === item.value) {
                      this.dataSource.principalLegalCertType = item.label;
                    }
                  });
                });
              } else {
                this.$notification.error({
                  message: res.data.message,
                });
              }
              this.spinning = false;
            }).catch(err => {
              this.spinning = false;
            });
          }
        });
      } else if (this.step === 1) {
        this.$refs.step1.form.validateFields(err => {
          if (!err) {
            if (this.$refs.step1.form1.partnerCode === 'xiaMenBank' && this.$refs.step1.form1.signedFlag === '0') {
              this.$refs.step1.visible2 = true;
              return;
            }
            // this.$refs.step1.showModal();
            this.$refs.step1.handleOk();
          }
        });
      }
    },
    confirm (form1) {
      this.$refs.step1.form.validateFields((err, values) => {
        if (!err) {
          // if (!values.checked) {
          //   this.$message.warning('请勾选协议');
          //   return false;
          // }
          console.log(values);
          this.spinning = true;
          if (form1.partnerCode === 'xiaMenBank') {
            this.upLoadList = {
              businessLicenseImage: !this.$refs.step1.file1 && values.businessLicenseImage ? values.businessLicenseImage.file.response.data.uri : this.$refs.step1.businessLicenseImage[0].url,
              legalPersonCardImage: !this.$refs.step1.file2 && values.legalPersonCardImage ? values.legalPersonCardImage.file.response.data.uri : this.$refs.step1.legalPersonCardImage[0].url,
              biddingDocumentUrl: !this.$refs.step1.file3 && values.biddingDocumentUrl ? values.biddingDocumentUrl.file.response.data.uri : this.$refs.step1.biddingDocumentUrl[0] ? this.$refs.step1.biddingDocumentUrl[0].url : '',
              // bidCommitmentUrl: !this.$refs.step1.file4 && values.bidCommitmentUrl ? values.bidCommitmentUrl.file.response.data.uri : this.$refs.step1.bidCommitmentUrl[0].url,
              // separateGuaranteePromiseUrl: !this.$refs.step1.file5 && values.separateGuaranteePromiseUrl ? values.separateGuaranteePromiseUrl.file.response.data.uri : this.$refs.step1.separateGuaranteePromiseUrl[0].url,
            };
          }
          const data = {
            orderNo: this.orderNo,
            responsiblePersonPhone: values.phoneNum,
            partnerCode: form1.partnerCode,
            ...this.upLoadList,
          };
          verify(data).then(res => {
            if (res.status === 200) {
              this.step = 2;
            } else {
              this.$notification.error({
                message: res.message,
              });
            }
            this.spinning = false;
          }).catch(err => {
            this.$refs.step1.hiddenModal();
            this.spinning = false;
            const errorMsg = err.response.data.message;
            this.$notification.error({
              message: errorMsg,
            });
          });
        }
      });
    },
    back () {
      this.step -= 1;
      if (this.step === 0) {
        this.orderNo = this.getQueryString('orderNo');
        getInformation({ orderNo: this.orderNo }).then(response => {
          this.dataSource = response.data;
          this.$refs.step1.form.setFieldsValue({
            principalName: this.dataSource.principalName,
            principalCertType: this.dataSource.principalCertType,
            principalIdCard: this.dataSource.principalIdCard,
            principalPhone: this.dataSource.principalPhone,
            principalLegalPerson: this.dataSource.principalLegalPerson,
            principalLegalCertType: this.dataSource.principalLegalCertType,
            principalLegalIdCard: this.dataSource.principalLegalIdCard,
            principalLegalPhone: this.dataSource.principalLegalPhone,
            marketers: this.dataSource.marketers,
          });
        });
      }
    },
    handlePickCompany (code) {
      this.pickCompanyCode = code;
    },
    closePage () {
      // if (navigator.userAgent.indexOf('Firefox') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) {
      //   window.location.href = 'about:blank';
      //   window.close();
      // } else {
      //   window.opener = null;
      //   window.open(' ', '_self');
      //   window.close();
      // }
      this.$router.back()
    },
    backPage () {
      this.$router.replace({
        path: '/orderCenter'
      });
    },
  },
  // computed: {
  //   invitationLetterStr() {
  //     const getStr = handlebars.compile(invitationLetterTpl);
  //     return getStr({
  //       insureNotice: this.$refs.notice,
  //       toCompanyName: 'toCompanyName',
  //       fromCompanyName: 'fromCompanyName',
  //       fromCompanyCode: 'fromCompanyCode',
  //       fromCompanyAddress: 'fromCompanyAddress',
  //       insuranceAmount: 'insuranceAmount',
  //       insuranceAmountInWords: 'insuranceAmountInWords',
  //       securityAmount: 'securityAmount',
  //       securityAmountInWords: 'securityAmountInWords',
  //     });
  //   },
  // },
};
</script>
<style lang="less" scoped>
.container {
  background-color: #fff;
  min-height: 726px;
  margin-top: 30px;
  position: relative;
}
.main {
  padding: 30px 50px 95px;
}
.pickCompany {
  margin-top: 90px;
}
.operate {
  margin-top: 80px;
}
// .notice-dialog {
::v-deep .ant-modal {
  width: 960px !important;
}
::v-deep .ant-modal-content {
  width: 960px !important;
  height: 616px !important;
  padding: 45px 50px;
  box-sizing: border-box;
}
::v-deep .ant-modal-body {
  padding: 24px 0 !important;
}
::v-deep .ant-modal-header {
  border-bottom: none;
}
::v-deep .ant-modal-title {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  text-align: center;
}
::v-deep .ant-modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
}
::v-deep .ant-modal-footer button {
  padding: 12px 26px;
  height: auto;
  font-size: 16px;
  line-height: 1;
  margin-left: 50px;
}
// }
.content {
  height: 392px;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: scroll;
  border: 1px solid #e5e5e5;
}
</style>
