import request from '@/utils/request'

export function code(params) {
  return request({
    url: '/api/insure/index/code',
    method: 'get',
    params,
  })
}
export function phoneCode(params) {
  return request({
    url: '/api/insure/index/phoneCode',
    method: 'get',
    params,
  })
}
export function smsCode(params) {
  return request({
    url: '/api/apply/smsCode',
    method: 'get',
    params,
  })
}
export function information(data) {
  return request({
    url: '/api/insure/index/information',
    method: 'post',
    data,
  })
}
export function getInformation(params) {
  return request({
    url: '/api/insure/index/info/information',
    method: 'get',
    params,
  })
}
export function verify(data) {
  return request({
    url: '/api/insure/index/verify',
    method: 'post',
    data,
  })
}
export function insureCancel(data) {
  return request({
    url: '/api/insure/index/insureCancel',
    method: 'post',
    data,
  })
}
export function insureInvoice(data) {
  return request({
    url: '/api/insure/index/insureInvoice',
    method: 'post',
    data,
  })
}
export function applyInsureMoneyVerify(data) {
  return request({
    url: '/api/insure/index/applyInsureMoneyVerify',
    method: 'post',
    data,
  })
}
export function submitEnterprise(data) {
  return request({
    url: '/api/enterpriseInfo/submitEnterprise',
    method: 'post',
    data,
  })
}
export function getEnterprise(params) {
  return request({
    url: '/api/enterpriseInfo/getEnterprise',
    method: 'get',
    params,
  })
}

export function getList(params) {
  return request({
    url: '/api/agencyBasicInfo/list',
    method: 'get',
    params,
  })
}

export function getChannelInfo(params) {
  return request({
    url: '/api/insure/index/getChannelInfo',
    method: 'get',
    params,
  })
}


export function certType() {
  return request({
    url: '/api/dictDetail?dictName=companyType',
    method: 'get',
  })
}

export function startSign(params) {
  return request({
    url: '/api/contract/esign/startSign',
    method: 'get',
    params,
  })
}

export function basicInfo(params) {
  return request({
    url: '/api/agencyBasicInfo/list',
    method: 'get',
    params,
  })
}

export function setInvoiceInfo(data) {
  return request({
    url: 'api/external/setInvoiceInfo',
    method: 'post',
    data,
  })
}

export function download(params) {
  return request({
    url: '/api/insure/index/download',
    method: 'get',
    params,
  })
}

// 获取人脸核验
export function getFaceCheck(data) {
  return request({
    url: '/api/risk/faceid/check',
    method: 'post',
    data
  })
}

// 获取人脸核查结果
export function getFaceCheckResult(params) {
  return request({
    url: '/api/risk/faceid/tencent/returnPage',
    method: 'get',
    params
  })
}


export default { download, smsCode, setInvoiceInfo, code, phoneCode, information, getInformation,
   verify, insureCancel, insureInvoice, applyInsureMoneyVerify, submitEnterprise, getEnterprise,
   getList, getChannelInfo, certType, startSign, basicInfo,
   getFaceCheck,getFaceCheckResult
  }
